<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
        <!-- 코덱 상태 리스트 -->
        <b-col
        xl="12"
        lg="12"
        md="12"
        class="ecs-card"
        >
          <fault-list></fault-list>
        </b-col>
        <!-- 코덱 상태 리스트 END -->
    </b-row>
    <fault-setting></fault-setting>
  </section>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import FaultList from './components/FaultList.vue'
import FaultSetting from './components/FaultSetting.vue'

export default {
  components: {
    BRow,
    BCol,
    FaultList,
    FaultSetting,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>