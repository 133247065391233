<template>
  <b-sidebar
    id="fault-setting"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
  
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0 font-weight-bolder">
          Room 1 - 초기값 설정
        </h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->
      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
            <!-- 초기값 설정 리스트 -->
            <b-table
            ref="refInvoiceListTable"
            :items="faultSettingCeItem"
            responsive
            :fields="faultSettingColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc"
            class="position-relative table-setting text-left"
            >
                <!-- 명령어 보기 Button -->
                <!-- <template #head(commend)>
                    <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="btn-sm float-right"
                    @click="hide"
                    >
                        명령어 보기
                    </b-button>
                </template> -->
                <!-- 명령어 보기 Button END -->
            </b-table>
            <!-- 초기값 설정 리스트 END -->

        <!-- Form Actions -->
        <div class="mt-2 text-center">
          <!-- 초기값 세팅 Button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="setSetting(hide)"
          >
            초기값 세팅
          </b-button>
          <!-- 초기값 세팅 Button End -->
          <!-- 취소 Button -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mr-2"
            @click="hide"
          >
            취소
          </b-button>
          <!-- 취소 Button End -->
        </div>
      </b-form>
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BTable, VBToggle,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar, BForm, BButton, BTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const refInvoiceListTable = ref(null)
    const perPage = ref(10)
    const totalInvoices = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const faultSettingColumns = [
        { key: 'ce_ver', label: '초기설정값이 아래와 같이 변경됩니다.'},
        { key: 'commend', label: ''},
    ]
    const faultSettingCeItem = [
        { 
            'ce_ver': '암호화 모드',
            'commend': '켜기/끄기',
        },
        {
          'ce_ver': '자동응답 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '자동응답모드 마이크 음소거',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '디폴트콜 프로토콜 지정',
          'commend': 'Auto/SIP/H323/H320/Spark',
        },
        {
          'ce_ver': 'H323 콜 셋업 모드',
          'commend': '다이렉트/게이트키퍼',
        },
        {
          'ce_ver': '네트워크 IP스택',
          'commend': 'IPv4/IPv6/Dual',
        },
        {
          'ce_ver': '네트워크서비스 H323 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '네트워크서비스 SIP 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': 'SIP 디폴트트랜스포트',
          'commend': 'UDP/TCP/TLS/AUTO',
        },
        {
          'ce_ver': 'SIP 리슨포트 켜기/끄기',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '유저인터페이스 정보 타입',
          'commend': 'SystemName',
        },
        {
          'ce_ver': 'OSD 암호화인디케이터',
          'commend': '자동/항상켜기/항상끄기',
        },
        {
          'ce_ver': '인터페이스 웹엑스 참가 아이콘',
          'commend': '숨기기/보이기',
        },
    ]

    const faultSettingTcItem = [
        { 
            'ce_ver': '암호화 모드',
            'commend': '켜기/끄기',
        },
        {
          'ce_ver': '자동응답 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '자동응답모드 마이크 음소거',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '디폴트콜 프로토콜 지정',
          'commend': 'Auto/SIP/H323/H320/Spark',
        },
        {
          'ce_ver': 'H323 콜 셋업 모드',
          'commend': '다이렉트/게이트키퍼',
        },
        {
          'ce_ver': '네트워크 IP스택',
          'commend': 'IPv4/IPv6/Dual',
        },
        {
          'ce_ver': '네트워크서비스 H323 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '네트워크서비스 SIP 모드',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': 'SIP 디폴트트랜스포트',
          'commend': 'UDP/TCP/TLS/AUTO',
        },
        {
          'ce_ver': 'SIP 리슨포트 켜기/끄기',
          'commend': '켜기/끄기',
        },
        {
          'ce_ver': '유저인터페이스 정보 타입',
          'commend': 'SystemName',
        },
        {
          'ce_ver': 'OSD 암호화인디케이터',
          'commend': '자동/항상켜기/항상끄기',
        },
        {
          'ce_ver': '인터페이스 웹엑스 참가 아이콘',
          'commend': '숨기기/보이기',
        },
    ]

    const resolveInvoiceStatusVariantAndIcon = status => {
        if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
        if (status === 'On') return { variant: 'success', icon: 'CheckCircleIcon' }
        if (status === 'On3') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
        if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
        if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
        if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
        if (status === 'On2') return { variant: 'primary', icon: 'CheckCircleIcon' }
        if (status === '') return { variant: 'danger', icon: 'InfoIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }
    const resolveClientAvatarVariant = status => {
        if (status === '예약일자') return 'primary'
        if (status === '예약시작') return 'danger'
        if (status === '예약종료') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'
        return 'primary'
    }
    

    return {
      perPage,
      perPageOptions,
      faultSettingCeItem,
      faultSettingTcItem,
      faultSettingColumns,
      sortBy,
      isSortDirDesc,
      totalInvoices,
      currentPage,
      refInvoiceListTable,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  methods: {
    setSetting(c) {
        if(window.confirm('초기값으로 설정하시겠습니까?')){
            return c()
        }
        return false
    }
  },
}
</script>

<style>

</style>
